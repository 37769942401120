// Import libraries.
import React, { CSSProperties } from "react";
import { connect } from "react-redux";
import { Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withI18n, withI18nProps } from "@lingui/react";
import { t } from "@lingui/macro";
import toast from "utils/Toast";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import User from "types/common/User";
import { TextFieldOptions } from "components/common/form/fields/TextField";

// Import components.
import { Divider } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import FieldWrapper from "components/common/form/FieldWrapper";
import TeamMenu from "./TeamMenu";
import TeamInfo from "types/models/TeamInfo";

interface STATE_PROPS {
    session: Session;
    currentUser: User | null;
    availableCompanies: TeamInfo[];
}
interface DISPATCH_PROPS {}
interface OWN_PROPS {
    open: boolean;
    itemHeight: number;
    itemCount: number;
    onToggleOpen: () => void;
    onAppChange: (companyId: string) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, withI18nProps {}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
        currentUser: state.currentUser,
        availableCompanies: state.availableCompanies,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {};
};

interface STATE {
    filter: string;
}

class TeamSelector extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        filter: "",
    };

    onFilterChange = (value: string) => {
        this.setState({ filter: value });
    };

    onAppChange = (companyId: string) => {
        const { i18n, session, currentUser, availableCompanies } = this.props;

        const targetCompany = availableCompanies.find((item) => item.companyId === companyId) || null;

        if (targetCompany) {
            if (session.companyId !== targetCompany.companyId) {
                if (targetCompany.requireMFA && !session.isSuper && session.authType !== "external" && !currentUser?.twoFactorAuthentication.enabled) {
                    toast.error(i18n._(t`Access denied. This team requires MFA be enabled on your account, edit your security settings to enable MFA.`));
                } else {
                    this.props.onAppChange(companyId);
                }
            }
        }

        this.props.onToggleOpen();
    };

    render() {
        const { i18n, classes, session, availableCompanies, itemHeight, itemCount, open } = this.props;
        const { filter } = this.state;

        return (
            <div id={"app-selector"} className={classes.root} style={{ height: open ? "calc(2.625em + " + itemHeight * itemCount + "px + 2px)" : 0 }}>
                <FieldWrapper
                    style={{ maxHeight: "2em" }}
                    type={"text"}
                    name={"filter"}
                    value={filter}
                    onChange={(_name: string, value: any) => this.onFilterChange(value)}
                    options={
                        {
                            placeholder: i18n._(t`Search by Team Name`),
                        } as TextFieldOptions
                    }
                />

                <Divider className={classes.divider} />

                <div style={{ flex: "0 0 auto", width: "100%", height: itemHeight * itemCount }}>
                    <AutoSizer>
                        {(args: { height: number; width: number }) => {
                            const { height, width } = args;

                            const sortedApps = availableCompanies.sort((a: TeamInfo, b: TeamInfo) => {
                                return a.companyName.localeCompare(b.companyName);
                            });

                            const filteredApps = sortedApps.filter((item) => {
                                return item.companyName.toLowerCase().includes(filter.toLowerCase()) || item.companyId.toLowerCase().includes(filter.toLowerCase());
                            });

                            return (
                                <FixedSizeList height={height} itemCount={filteredApps.length} itemSize={itemHeight} width={width}>
                                    {(args: { index: number; style: CSSProperties }) => {
                                        const { index, style } = args;
                                        return (
                                            <TeamMenu
                                                key={index}
                                                style={{ minHeight: itemHeight, maxHeight: itemHeight, ...style }}
                                                height={itemHeight}
                                                teamInfo={filteredApps[index]}
                                                disabled={false}
                                                selected={filteredApps[index].companyId === session.companyId}
                                                onClick={(companyId?: string) => {
                                                    if (companyId) this.onAppChange(companyId);
                                                }}
                                            />
                                        );
                                    }}
                                </FixedSizeList>
                            );
                        }}
                    </AutoSizer>
                </div>
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "var(--navigation-app-selector-background-color, inherit)",
            color: "var(--navigation-app-selector-color, inherit)",
            borderColor: "var(--navigation-app-selector-border-color, inherit)",

            overflow: "hidden",

            transition: "height 0.2s linear",
        },

        divider: {
            backgroundColor: "var(--navigation-app-selector-border-color, inherit)",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(TeamSelector)));
