// Import libraries.
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { Trans } from "@lingui/macro";
import classnames from "classnames";

// Import types.
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import AppStatus from "types/enums/AppStatus";
import PlayerSummary from "types/models/PlayerSummaryInfo";

// Import components.
import TeamBanner from "./TeamBanner";
import AppBanner from "./AppBanner";
import PlayerBanner from "./PlayerBanner";
import Tabs from "components/common/tabs";

interface OWN_PROPS {
    teamPlanSwapEnabled: boolean;
    teamInfo: TeamInfo | null;
    appInfo: AppInfo | null;
    playerSummary: PlayerSummary | null;
    isTeamAdmin: boolean;
    open: boolean;
    mode: "team" | "app" | "player";
    onToggle: (mode: "team" | "app" | "player", open?: boolean, saveToLocalStorage?: boolean) => void;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps {}

class Banner extends React.PureComponent<PROPS> {
    handleTabChange = (mode: string) => {
        this.props.onToggle(mode as "team" | "app" | "player", true);
    };
    render() {
        const { location, classes, open, mode, teamPlanSwapEnabled, teamInfo, appInfo, playerSummary, isTeamAdmin } = this.props;
        const bannerModes = ["team", "app", "player"];

        const appStatus = teamInfo && teamInfo.accountStatus === "PoorStanding" ? (teamInfo?.serviceStatus !== "Suspended" ? AppStatus.APPROACHING_SUSPENSION : AppStatus.SUSPENDED) : appInfo?.app_status;

        return (
            <div className={classnames({ [classes.root]: true, [classes.closed]: !open || location.pathname.startsWith("/super") })}>
                <Tabs
                    orientation={"vertical"}
                    value={bannerModes.indexOf(mode)}
                    scrollButtons={false}
                    className={classnames(classes.tabs, appStatus === AppStatus.APPROACHING_SUSPENSION ? classes.approachingSuspension : appStatus === AppStatus.SUSPENDED ? classes.suspended : undefined)}
                    onTabChanged={(index: number) => this.handleTabChange(bannerModes[index])}
                    configs={[
                        {
                            label: <Trans>Team</Trans>,
                            component: <TeamBanner isTeamAdmin={isTeamAdmin} teamInfo={teamInfo} />,
                        },
                        {
                            label: <Trans>App</Trans>,
                            component: <AppBanner teamPlanSwapEnabled={teamPlanSwapEnabled} teamInfo={teamInfo} appInfo={appInfo} />,
                        },
                        ...(playerSummary
                            ? [
                                  {
                                      label: <Trans>User</Trans>,
                                      component: <PlayerBanner playerSummary={playerSummary} />,
                                  },
                              ]
                            : []),
                    ]}
                />
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "stretch",

            backgroundColor: "var(--primary-breadcrumbs-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            borderColor: "var(--secondary-border-color, inherit)",

            position: "relative",
            overflow: "hidden",

            "& > *": {
                overflowY: "hidden",
                overflowX: "auto",
            },

            maxHeight: "calc(6.5em + var(--scrollbar-size, 0))",

            borderBottomStyle: "solid",
            borderBottomWidth: "0.0625em",

            transition: "max-height 0.2s linear, border-bottom-width 0.2s linear",
        },
        tabs: {
            flex: "1 1 auto",
            margin: "0 .5em .5em",
            display: "flex",
            alignItems: "flex-start",
            borderRadius: "0.5em",

            "& .MuiTabs-root": {
                borderRightStyle: "none",
                minHeight: "5.6em",
                maxHeight: "unset",

                background: "none",

                "& .MuiTab-root": {
                    minHeight: "unset",
                    maxHeight: "unset",

                    minWidth: "5em",
                    maxWidth: "5em",

                    textTransform: "none",
                    fontSize: "0.75em",

                    borderTopLeftRadius: "0.5em",
                    borderBottomLeftRadius: "0.5em",

                    color: "var(--header-color, inherit)",
                    background: "none",
                },
            },
            "& .MuiTabs-root .MuiTabs-indicator": {
                display: "none",
            },

            "& .MuiTabs-root #tab-0.MuiTab-root": {
                "&.Mui-selected": {
                    backgroundColor: "var(--navigation-team-context-background-color, inherit)",
                    color: "var(--navigation-team-context-color, inherit)",
                },

                "&:hover": {
                    color: "var(--navigation-team-context-color, inherit)",
                },
            },

            "& .MuiTabs-root #tab-1.MuiTab-root": {
                "&.Mui-selected": {
                    backgroundColor: "var(--navigation-app-context-background-color, inherit)",
                    color: "var(--navigation-app-context-color, inherit)",
                },

                "&:hover": {
                    color: "var(--navigation-app-context-color, inherit)",
                },
            },

            "& .MuiTabs-root #tab-2.MuiTab-root": {
                "&.Mui-selected": {
                    backgroundColor: "var(--navigation-user-context-background-color, inherit)",
                    color: "var(--navigation-user-context-color, inherit)",
                },

                "&:hover": {
                    color: "var(--navigation-user-context-color, inherit)",
                },
            },

            "& #tabpanel-0": {
                borderLeft: "0.3125em solid var(--navigation-team-context-color, inherit)",
            },
            "& #tabpanel-1": {
                borderLeft: "0.3125em solid var(--navigation-app-context-color, inherit)",
            },
            "& #tabpanel-2": {
                borderLeft: "0.3125em solid var(--navigation-user-context-color, inherit)",
            },
        },
        approachingSuspension: {
            "& .MuiTabs-root #tab-1.MuiTab-root": {
                "&.Mui-selected": {
                    backgroundColor: "var(--system-message-banner-warning-background-color, inherit)",
                    color: "var(--label-warning-color, inherit)",
                },

                "&:hover": {
                    color: "var(--label-warning-color, inherit)",
                },
            },

            "& #tabpanel-1": {
                borderLeft: "0.3125em solid var(--chip-warning-background-color, inherit)",
            },
        },
        suspended: {
            "& .MuiTabs-root #tab-1.MuiTab-root": {
                "&.Mui-selected": {
                    backgroundColor: "var(--system-message-banner-error-background-color, inherit)",
                    color: "var(--label-negative-color, inherit)",
                },

                "&:hover": {
                    color: "var(--label-negative-color, inherit)",
                },
            },

            "& #tabpanel-0, & #tabpanel-1": {
                borderLeft: "0.3125em solid var(--chip-negative-background-color, inherit)",
            },
        },
        closed: {
            maxHeight: 0,
            borderBottomWidth: 0,
        },
    });

export default withRouter(withStyles(styles)(Banner));
