import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import LocalStorageUtils from "utils/LocalStorage";
import RouteParamUtils from "utils/RouteParam";

export const DeepLinkGuard: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const location = useLocation<any>();
    const history = useHistory<any>();
    const match = useRouteMatch();

    const pathname = useMemo(() => location.pathname, [location]);

    const [isProcessing, setIsProcessing] = useState(false);

    const routeParametersPresent = useMemo(() => !isProcessing && RouteParamUtils.routeParametersPresent<any>(match, location), [isProcessing, location, match]);
    const queryStringPresent = useMemo(() => routeParametersPresent && RouteParamUtils.locationQueryStringParamsPresent(location), [location, routeParametersPresent]);

    const getStartingHash = useCallback(() => {
        const startingHash = LocalStorageUtils.getItem("starting_hash") || null;

        return startingHash;
    }, []);

    useEffect(() => {
        const startingHash = getStartingHash();

        if (startingHash) {
            console.log("Restoring Starting Hash", startingHash);

            LocalStorageUtils.deleteItem("starting_hash");

            history.push(startingHash);
        }
    }, [getStartingHash, history]);

    useEffect(() => {
        const startingHash = getStartingHash();

        if (!startingHash && queryStringPresent) {
            setIsProcessing(true);

            const routeParameters = RouteParamUtils.extractRouteParameters<any>(match, location);

            console.debug("Extracted query parameters", pathname, routeParameters, location.state);

            history.replace({ pathname, search: "", state: routeParameters });

            window.setTimeout(() => {
                console.debug("Extracted query parameters (DONE)");

                setIsProcessing(false);
            }, 0);
        }
    }, [getStartingHash, history, location, match, pathname, queryStringPresent]);

    return <>{isProcessing || queryStringPresent ? null : children}</>;
};
