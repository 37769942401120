// Import libraries.
import Http, { HttpResponse } from "utils/networking/Http";

// Import types.
import AppDailyCounts, { processAppDailyCounts } from "types/models/AppDailyCounts";
import AppAggregateCounts, { processAppAggregateCounts } from "types/models/AppAggregateCounts";

export const getAppDailyCounts = async (date?: Date, abortSignal?: AbortSignal): Promise<AppDailyCounts> => {
    const response: HttpResponse = await Http.GET("admin/reporting/dailyGameCounts-read", { dateInMillis: (date != null ? date : new Date()).getTime() }, Http.JSON_HEADERS, abortSignal);

    if (abortSignal?.aborted) {
        throw new DOMException("Aborted", "AbortError");
    }

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processAppDailyCounts(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

export const getAppAggregateCounts = async (abortSignal?: AbortSignal): Promise<AppAggregateCounts> => {
    const response: HttpResponse = await Http.GET("admin/reporting/aggregateGameCounts-read", null, Http.JSON_HEADERS, abortSignal);

    if (abortSignal?.aborted) {
        throw new DOMException("Aborted", "AbortError");
    }

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processAppAggregateCounts(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

export const getAppCurrentUserCount = async (abortSignal?: AbortSignal): Promise<number | null> => {
    const response: HttpResponse = await Http.GET("admin/reporting/getUpToDateUserCount", null, Http.JSON_HEADERS, abortSignal);

    if (abortSignal?.aborted) {
        throw new DOMException("Aborted", "AbortError");
    }

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data ? processAppCurrentUserCount(response.data) : null);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const processAppCurrentUserCount = (data: any): number => {
    return Number.parseInt(data.upToDateUserCount);
};

const Services = {
    getAppDailyCounts,
    getAppAggregateCounts,
    getAppCurrentUserCount,
};

export default Services;
