import { match } from "react-router-dom";
import QueryString from "query-string";
import * as H from "history";

import StringUtils from "./String";

/**
 * Route utility functions.
 */
export default abstract class RouteParamUtils {
    /**
     * Determines whether or not match params are present.
     *
     * Optionally accepts an array of fieldNames that can be used to limit the fields that are processed.
     *
     * @param match
     * @param fieldNames
     * @returns
     */
    public static matchParamsPresent<Params extends { [K in keyof Params]?: any } = {}>(match: match<Params | null> | null, fieldNames?: string[]) {
        if (match != null && match.params != null) {
            return Object.keys(match.params).some((key) => {
                if (fieldNames == null || fieldNames.includes(key)) {
                    const value = (match.params as any)[key];

                    switch (typeof value) {
                        case "string":
                            return !StringUtils.isNullOrEmpty(value as string);
                        default:
                            return value != null;
                    }
                } else {
                    return false;
                }
            });
        }

        return false;
    }

    /**
     * Determines whether or not location query string params are present.
     *
     * Optionally accepts an array of fieldNames that can be used to limit the fields that are processed.
     *
     * @param location
     * @param fieldNames
     * @returns
     */
    public static locationQueryStringParamsPresent<Params extends { [K in keyof Params]?: any } = {}>(location: H.Location | null, fieldNames?: string[]) {
        if (location != null && !StringUtils.isNullOrEmpty(location.search)) {
            let queryString = location.search.indexOf("?") >= 0 ? location.search.substring(location.search.indexOf("?") + 1) : null;

            if (queryString?.includes("#")) queryString = queryString.substring(0, queryString.indexOf("#"));

            const queryParams = queryString ? QueryString.parse(queryString) : {};

            return Object.keys(queryParams).some((key) => {
                if (fieldNames == null || fieldNames.includes(key)) {
                    const value = (queryParams as any)[key];

                    switch (typeof value) {
                        case "string":
                            return !StringUtils.isNullOrEmpty(value as string);
                        default:
                            return value != null;
                    }
                } else {
                    return false;
                }
            });
        }

        return false;
    }

    private static convertToType(value: any, type: "string" | "number" | "boolean") {
        switch (type) {
            case "string":
                if (typeof value !== "string") {
                    if (value != null) {
                        return "" + value;
                    } else {
                        return null;
                    }
                } else {
                    return value;
                }
            case "number":
                if (typeof value !== "number") {
                    if (value != null) {
                        switch (typeof value) {
                            case "string":
                                return value.includes(".") ? Number.parseFloat(value) : Number.parseInt(value);
                            case "boolean":
                                return value === true ? 1 : 0;
                            default:
                                return null;
                        }
                    } else {
                        return null;
                    }
                } else {
                    return value;
                }
            case "boolean":
                if (typeof value !== "boolean") {
                    if (value != null) {
                        switch (typeof value) {
                            case "string":
                                return StringUtils.isTruthy(value);
                            case "number":
                                return value !== 0;
                            default:
                                return null;
                        }
                    } else {
                        return null;
                    }
                } else {
                    return value;
                }
            default:
                return null;
        }
    }

    /**
     * Determines whether or not location state params are present.
     *
     * Optionally accepts an array of fieldNames that can be used to limit the fields that are processed.
     *
     * @param location
     * @param fieldNames
     * @returns
     */
    public static locationStateParamsPresent<Params extends { [K in keyof Params]?: any } = {}>(location: H.Location | null, fieldNames?: string[]) {
        if (location != null && location.state != null) {
            return Object.keys(location.state as Params).some((key) => {
                if (fieldNames == null || fieldNames.includes(key)) {
                    const value = (location.state as any)[key];

                    switch (typeof value) {
                        case "string":
                            return !StringUtils.isNullOrEmpty(value as string);
                        default:
                            return value != null;
                    }
                } else {
                    return false;
                }
            });
        }

        return false;
    }

    /**
     * Determines whether or not route parameters are present (in the match params, location query string params or location state params).
     *
     * Order of priority: match params, location query string params and finally location state params.
     *
     * Optionally accepts an array of fieldNames that can be used to limit the fields that are processed.
     *
     * @param match
     * @param location
     * @param fieldNames
     * @returns
     */
    public static routeParametersPresent<Parameters extends { [K in keyof Parameters]?: any } = {}>(match: match<Parameters | null> | null, location: H.Location | null, fieldNames?: string[]) {
        return RouteParamUtils.matchParamsPresent<Parameters>(match, fieldNames) || RouteParamUtils.locationQueryStringParamsPresent<Parameters>(location, fieldNames) || RouteParamUtils.locationStateParamsPresent<Parameters>(location, fieldNames);
    }

    /**
     * Extracts the route parameters (from either the match params, location query string params or location state params).
     *
     * Order of priority: match params, location query string params and finally location state params.
     *
     * Optionally accepts an array of fieldNames that can be used to limit the fields that are extracted.
     *
     * Optionally accepts an array of fieldTypes that can be used to coerce the values into expected types (a null fieldType will result in the coercion being skipped).
     *
     * @param match
     * @param location
     * @param fieldNames
     * @param fieldTypes
     * @returns
     */
    public static extractRouteParameters<Parameters extends { [K in keyof Parameters]?: any } = {}>(match: match<Parameters | null> | null, location: H.Location | null, fieldNames?: string[], fieldTypes?: ("string" | "number" | "boolean" | null)[]) {
        const result: any = {};

        if (RouteParamUtils.matchParamsPresent<Parameters>(match)) {
            if (match != null && match.params != null) {
                Object.keys(match.params).forEach((key, idx) => {
                    if (fieldNames == null || fieldNames.includes(key)) {
                        result[key] = (match.params as any)[key];

                        if (fieldTypes != null) {
                            const fieldType = fieldTypes[idx];

                            if (fieldType != null) {
                                result[key] = RouteParamUtils.convertToType(result[key], fieldType);
                            }
                        }
                    }
                });
            }
        } else {
            if (RouteParamUtils.locationQueryStringParamsPresent(location, fieldNames)) {
                if (location != null && !StringUtils.isNullOrEmpty(location.search)) {
                    let queryString = location.search.indexOf("?") >= 0 ? location.search.substring(location.search.indexOf("?") + 1) : null;

                    if (queryString?.includes("#")) queryString = queryString.substring(0, queryString.indexOf("#"));

                    const queryParams = queryString ? QueryString.parse(queryString) : {};

                    Object.keys(queryParams).forEach((key, idx) => {
                        if (fieldNames == null || fieldNames.includes(key)) {
                            result[key] = (queryParams as any)[key];

                            if (fieldTypes != null) {
                                const fieldType = fieldTypes[idx];

                                if (fieldType != null) {
                                    result[key] = RouteParamUtils.convertToType(result[key], fieldType);
                                }
                            }
                        }
                    });
                }
            } else {
                if (RouteParamUtils.locationStateParamsPresent<Parameters>(location)) {
                    if (location != null && location.state != null) {
                        Object.keys(location.state as any).forEach((key, idx) => {
                            if (fieldNames == null || fieldNames.includes(key)) {
                                result[key] = (location.state as any)[key];

                                if (fieldTypes != null) {
                                    const fieldType = fieldTypes[idx];

                                    if (fieldType != null) {
                                        result[key] = RouteParamUtils.convertToType(result[key], fieldType);
                                    }
                                }
                            }
                        });
                    }
                }
            }
        }

        return result as Parameters;
    }

    /**
     * Determines whether or not the route parameters have been modified.
     *
     * This function accepts the previous and new versions of the match and location instances.
     * These are used as the source of truth for the parameters that end up getting compared.
     *
     * Optionally accepts an array of fieldNames that can be used to limit the fields that are compared.
     *
     * @param prevMatch
     * @param newMatch
     * @param prevLocation
     * @param newLocation
     * @param fieldNames
     * @returns
     */
    public static routeParametersHaveChanged<Parameters extends { [K in keyof Parameters]?: any } = {}>(
        prevMatch: match<Parameters | null> | null,
        newMatch: match<Parameters | null> | null,
        prevLocation: H.Location | null,
        newLocation: H.Location | null,
        fieldNames?: string[]
    ) {
        const prevParameters = RouteParamUtils.extractRouteParameters<Parameters>(prevMatch, prevLocation, fieldNames);
        const newParameters = RouteParamUtils.extractRouteParameters<Parameters>(newMatch, newLocation, fieldNames);

        const prevKeys = Object.keys(prevParameters).sort((a, b) => a.localeCompare(b));
        const newKeys = Object.keys(newParameters).sort((a, b) => a.localeCompare(b));

        if (JSON.stringify(prevKeys) !== JSON.stringify(newKeys)) {
            return true;
        } else {
            return (
                Object.keys(prevParameters).find((key) => {
                    return (prevParameters as any)[key] !== (newParameters as any)[key];
                }) != null
            );
        }
    }
}
