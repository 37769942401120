// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { toast } from "utils/Toast";

// Import types.
import PortalRouteDefinition from "types/common/PortalRouteDefinition";
import Session from "types/common/Session";
import AppInfo from "types/models/AppInfo";
import PlayerSummary from "types/models/PlayerSummaryInfo";
import TeamInfo from "types/models/TeamInfo";
import PortalState from "types/store";

// Import utils.
import LocalStorageUtils from "utils/LocalStorage";
import StringUtils from "utils/String";

// Import components.
import Banner from "../Banner";
import BreadcrumbBar from "../BreadcrumbBar";

// Import services.
import BrainCloudPropertiesService from "services/BrainCloudProperties";
import Services from "./services";

interface STATE_PROPS {
    session: Session;
    availableCompanies: TeamInfo[];
    availableApps: AppInfo[];
}
interface STATE {
    teamPlanSwapEnabled: boolean;
    teamInfo: TeamInfo | null;
    appInfo: AppInfo | null;
    isReady: boolean;
}

interface OWN_PROPS {
    definitions: PortalRouteDefinition[];
    bannerOpen: boolean;
    bannerMode: "team" | "app" | "player";
    onToggle: (mode: "team" | "app" | "player", open?: boolean, saveToLocalStorage?: boolean) => void;
}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
        availableCompanies: state.availableCompanies,
        availableApps: state.availableApps,
    };
};
interface PROPS extends OWN_PROPS, STATE_PROPS, RouteComponentProps {}

class BreadcrumbBarAndBanner extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        teamPlanSwapEnabled: false,
        teamInfo: null,
        appInfo: null,
        isReady: false,
    };
    private _abortController: AbortController = new AbortController();
    private _isMounted: boolean = false;

    componentDidMount() {
        const { location, bannerOpen, bannerMode } = this.props;

        this._isMounted = true;

        this.loadInfo();

        let open = bannerOpen;
        let mode = bannerMode;

        if (!location.pathname.startsWith("/super")) {
            if (LocalStorageUtils.getItem("isBannerOpen") != null) {
                open = LocalStorageUtils.getItem("isBannerOpen") === "true";
            }
        }

        if (["/team/dashboard", "/app/dashboard"].includes(location.pathname)) {
            open = true;
        }

        if (location.pathname.startsWith("/team")) {
            mode = "team";
        }

        if (location.pathname.startsWith("/app")) {
            mode = "app";
        }

        if (location.pathname.startsWith("/user")) {
            mode = "player";
        }

        if (bannerMode !== mode || bannerOpen !== open) {
            this.props.onToggle(mode, open);
        }
    }

    componentDidUpdate(prevProps: PROPS) {
        const { session, location, availableCompanies, availableApps, bannerOpen, bannerMode } = this.props;
        const { playerSummary } = session;

        if (
            prevProps.session.companyId !== session.companyId ||
            prevProps.session.companyIdAlias !== session.companyIdAlias ||
            prevProps.session.appId !== session.appId ||
            prevProps.session.playerId !== session.playerId ||
            prevProps.availableCompanies !== availableCompanies ||
            prevProps.availableApps !== availableApps
        ) {
            this._abortController.abort();
            this._abortController = new AbortController();

            this.loadInfo();
        }

        let open = bannerOpen;
        let mode = bannerMode;

        if (prevProps.location.pathname !== location.pathname) {
            if (!location.pathname.startsWith("/super")) {
                if (LocalStorageUtils.getItem("isBannerOpen") != null) {
                    open = LocalStorageUtils.getItem("isBannerOpen") === "true";
                }
            }

            if (["/team/dashboard", "/app/dashboard"].includes(location.pathname)) {
                open = true;
            }

            if (!prevProps.location.pathname.startsWith("/team") && location.pathname.startsWith("/team")) {
                mode = "team";
            }

            if (!prevProps.location.pathname.startsWith("/app") && location.pathname.startsWith("/app")) {
                mode = "app";
            }

            if (!prevProps.location.pathname.startsWith("/user") && location.pathname.startsWith("/user")) {
                mode = "player";
            }

            if (prevProps.location.pathname.startsWith("/user") && !location.pathname.startsWith("/user") && location.pathname.startsWith("/app")) {
                mode = "app";
            }
        }
        if (location.pathname.startsWith("/user") && !playerSummary) {
            if (mode === "player") mode = "app";
        }

        if (prevProps.session.playerSummary == null && playerSummary != null && location.pathname.startsWith("/user")) {
            mode = "player";
        }

        if (bannerOpen !== open || bannerMode !== mode) {
            this.props.onToggle(mode, open);
        }
    }

    componentWillUnmount(): void {
        this._isMounted = false;

        this._abortController.abort();
    }

    loadInfo = async () => {
        const { session } = this.props;

        const abortSignal = this._abortController.signal;

        this.setState({ teamInfo: null, appInfo: null, teamPlanSwapEnabled: false, isReady: false });

        try {
            const companyId = session.isSuper ? session.companyIdAlias : session.companyId;
            const appId = session.appId;

            const [enableTeamPlanSwap, teamInfo, appInfo] = await Promise.all([
                BrainCloudPropertiesService.getProperty("enableTeamPlanSwap"),
                companyId ? Services.getTeam(companyId, abortSignal) : null,
                companyId && appId ? Services.getApps(abortSignal) : null,
            ]);

            if (this._isMounted) {
                this.setState({
                    teamPlanSwapEnabled: enableTeamPlanSwap != null ? StringUtils.isTruthy(enableTeamPlanSwap) : false,
                    teamInfo: teamInfo,
                    appInfo: appInfo ? appInfo?.find((item) => item.appId === appId) || null : null,
                    isReady: true,
                });
            }
        } catch (error: any) {
            if (error.name === "AbortError") {
                console.warn("BreadcrumbBarAndBanner - loadInfo: Operation Cancelled");
            } else {
                toast.error(error);
            }

            if (this._isMounted) {
                this.setState({ isReady: true });
            }
        }
    };

    render() {
        const { bannerOpen, bannerMode, onToggle, definitions, session } = this.props;
        const { teamPlanSwapEnabled, teamInfo, appInfo } = this.state;
        const { playerSummary } = session;

        return (
            <>
                <BreadcrumbBar definitions={definitions} bannerOpen={bannerOpen} bannerMode={bannerMode} teamInfo={teamInfo} appInfo={appInfo} playerSummary={playerSummary as PlayerSummary} onToggleBannerOpen={onToggle} />

                <Banner
                    open={bannerOpen}
                    mode={bannerMode}
                    onToggle={onToggle}
                    teamPlanSwapEnabled={teamPlanSwapEnabled}
                    teamInfo={teamInfo}
                    appInfo={appInfo}
                    playerSummary={playerSummary as PlayerSummary}
                    isTeamAdmin={session.isTeamAdmin === true}
                />
            </>
        );
    }
}
export default connect(mapStateToProps)(withRouter(BreadcrumbBarAndBanner));
