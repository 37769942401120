// Import libraries.
import Http, { HttpResponse } from "utils/networking/Http";

// Import types.
import TeamInfo, { processTeamInfo } from "types/models/TeamInfo";
import AppInfo, { processAppInfo } from "types/models/AppInfo";
import AppDailyCounts, { processAppDailyCounts } from "types/models/AppDailyCounts";

export const getTeam = async (teamId: string, abortSignal?: AbortSignal): Promise<TeamInfo> => {
    const response: HttpResponse = await Http.GET("admin/serveradmin/company-read", { companyId: teamId }, Http.JSON_HEADERS, abortSignal);

    if (abortSignal?.aborted) {
        throw new DOMException("Aborted", "AbortError");
    }

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processTeamInfo(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

export const getApps = async (abortSignal?: AbortSignal): Promise<AppInfo[]> => {
    const response: HttpResponse = await Http.GET("admin/serveradmin/team-apps-read", null, Http.JSON_HEADERS, abortSignal);

    if (abortSignal?.aborted) {
        throw new DOMException("Aborted", "AbortError");
    }

    if (Http.isStatusOk(response)) {
        return Promise.resolve(response.data && Array.isArray(response.data) ? response.data.map(processAppInfo) : []);
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

export const getAppDailyCounts = async (date?: Date, abortSignal?: AbortSignal): Promise<AppDailyCounts> => {
    const response: HttpResponse = await Http.GET("admin/reporting/dailyGameCounts-read", { dateInMillis: (date != null ? date : new Date()).getTime() }, Http.JSON_HEADERS, abortSignal);

    if (abortSignal?.aborted) {
        throw new DOMException("Aborted", "AbortError");
    }

    if (Http.isStatusOk(response)) {
        return Promise.resolve(processAppDailyCounts(response.data));
    } else {
        return Promise.reject(Http.buildError(response));
    }
};

const Services = {
    getTeam: getTeam,
    getApps: getApps,
    getAppDailyCounts: getAppDailyCounts,
};

export default Services;
