// Import libraries.
import React from "react";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import { withPermission, WithPermissionProps } from "framework/withPermission";
import { withI18n, withI18nProps } from "@lingui/react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { toast } from "utils/Toast";

// Import types.
import PortalState from "types/store";
import Session from "types/common/Session";
import DialogMode from "types/enums/DialogMode";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import RunState, { formatRunState, formatRunStateColor } from "types/enums/RunState";
import { formatPublishStateChipBackgroundColor, formatPublishStateChipColor, formatPublishStateLabel } from "types/enums/PublishState";
import { formatAppStatusChipBackgroundColor, formatAppStatusChipColor, formatAppStatusLabel, AppStatus } from "types/enums/AppStatus";

// Import components.
import { Chip, Divider, Link, Typography } from "@mui/material";
import Tooltip from "components/common/Tooltip";
import SendIntercomMessageDialog from "components/common/dialog/App/SendIntercomMessageDialog";
import ImageWrapper from "components/common/ImageWrapper";
import NoAppImage from "components/icons/NoAppImage";
import LabelWithCopy from "components/common/LabelWithCopy";
import IconButton from "components/common/button/IconButton";
import EllipsisInCenter from "components/common/EllipsisInCenter";
import ChooseBillingPlanDialog from "components/common/dialog/App/ChooseBillingPlanDialog";
import LoadingProgress from "components/common/widgets/LoadingProgress";
import FixBillingLink from "components/common/widgets/FixBillingLink";
import TrendArrow from "components/common/trendArrow";

// Import icons.
import EmailIcon from "@mui/icons-material/Email";

// Import utils.
import StringUtils from "utils/String";
import NumberFormatter from "utils/formatters/Number";
import DateTimeFormatter from "utils/formatters/DateTime";

// Import services.
import Services from "./services";

interface DailyData {
    revenue: { today: number; yesterdayTillNow: number; yesterday: number; percentageDiff: number };
    dau: { today: number; yesterdayTillNow: number; yesterday: number; percentageDiff: number };
}

interface MonthlyData {
    revenue: { currentMonth: number; lastMonthTillNow: number; lastMonth: number; percentageDiff: number };
    mau: { currentMonth: number; lastMonthTillNow: number; lastMonth: number; percentageDiff: number };
}

interface STATE {
    sendIntercomMessageDialogMode: DialogMode.CREATE | null;
    chooseBillingPlanDialogMode: DialogMode | null;
    dailyData: DailyData;
    monthlyData: MonthlyData;
    totalRevenue: number;
    isBusy: boolean;
}
interface STATE_PROPS {
    session: Session;
}
interface DISPATCH_PROPS {
    populateAvailableApps: () => void;
}
interface OWN_PROPS {
    teamPlanSwapEnabled: boolean;
    teamInfo: TeamInfo | null;
    appInfo: AppInfo | null;
}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        populateAvailableApps: () => dispatch({ type: "app.populateAvailableApps" }),
    };
};
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, WithPermissionProps, withI18nProps, RouteComponentProps {}

const emptyDailyData: DailyData = { revenue: { today: 0, yesterdayTillNow: 0, yesterday: 0, percentageDiff: 0 }, dau: { today: 0, yesterdayTillNow: 0, yesterday: 0, percentageDiff: 0 } };
const emptyMonthlyData: MonthlyData = { revenue: { currentMonth: 0, lastMonthTillNow: 0, lastMonth: 0, percentageDiff: 0 }, mau: { currentMonth: 0, lastMonthTillNow: 0, lastMonth: 0, percentageDiff: 0 } };

class AppBanner extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        sendIntercomMessageDialogMode: null,
        chooseBillingPlanDialogMode: null,
        dailyData: emptyDailyData,
        monthlyData: emptyMonthlyData,
        totalRevenue: 0,
        isBusy: true,
    };
    private _abortController: AbortController = new AbortController();
    private _isMounted: boolean = false;

    componentDidMount(): void {
        console.log("Mounting App Banner");

        this._isMounted = true;

        this.loadData();
    }

    componentDidUpdate(prevProps: Readonly<PROPS>): void {
        if (prevProps.appInfo?.appId !== this.props.appInfo?.appId) {
            this._abortController.abort();
            this._abortController = new AbortController();

            this.loadData();
        }
    }

    componentWillUnmount(): void {
        this._isMounted = false;

        this._abortController.abort();
    }

    loadData = async () => {
        const { appInfo } = this.props;

        const abortSignal = this._abortController.signal;

        this.setState({ dailyData: emptyDailyData, monthlyData: emptyMonthlyData, isBusy: true });

        if (appInfo) {
            try {
                const [monthlyData, dailyData] = await Promise.all([this.getMonthlyData(abortSignal), this.getDailyData(abortSignal)]);

                if (this._isMounted) {
                    this.setState({ dailyData: dailyData || emptyDailyData, monthlyData: monthlyData || emptyMonthlyData, isBusy: false });
                }
            } catch (error: any) {
                if (error.name === "AbortError") {
                    console.debug("AppBanner - loadData: Operation Cancelled");
                } else {
                    toast.error(error);
                }

                if (this._isMounted) {
                    this.setState({ isBusy: false });
                }
            }
        }
    };

    openChooseBillingPlanDialog = () => {
        this.setState({ chooseBillingPlanDialogMode: DialogMode.UPDATE });
    };

    closeChooseBillingPlanDialog = (confirmed: boolean) => {
        this.setState({ chooseBillingPlanDialogMode: null }, () => {
            if (confirmed) {
                this.props.populateAvailableApps();
            }
        });
    };

    getMonthlyData = async (abortSignal?: AbortSignal) => {
        // Fetch aggregate counts for the app
        const appAggregateCounts = await Services.getAppAggregateCounts(abortSignal);

        // Get current date, day, and hour in UTC
        const currentDate = new Date();
        const currentDay = currentDate.getUTCDate();
        const currentHour = currentDate.getUTCHours();

        // Get current month and its history
        const currentMonth = currentDate.toISOString().slice(0, 7);
        const currentMonthHistory = appAggregateCounts.monthlyHistory[currentMonth];

        // Get current month's revenue or initialize to 0 if data is not available
        const currentMonthRevenue = currentMonthHistory?.revenue || 0;

        const totalRevenue = appAggregateCounts.revenue.count;
        if (this._isMounted) this.setState({ totalRevenue });

        // Get MAU count for the current month
        const currentDateStats = await Services.getAppDailyCounts(currentDate, abortSignal);
        const currentMonthMAU = currentDate.getDate() === 1 ? currentDateStats.users.count : currentDateStats.mauCount;

        // Calculate last month's date and get its history
        const lastMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1, 0);
        const lastMonth = lastMonthDate.toISOString().slice(0, 7);
        const lastMonthHistory = appAggregateCounts.monthlyHistory[lastMonth];

        // If there's no data available for last month, return default values
        if (!lastMonthHistory) {
            console.log("last Month History data not available");

            return {
                revenue: { currentMonth: currentMonthRevenue, lastMonthTillNow: 0, lastMonth: 0, percentageDiff: 0 },
                mau: { currentMonth: currentMonthMAU, lastMonthTillNow: 0, lastMonth: 0, percentageDiff: 0 },
            };
        }

        // Destructure last month's revenue and MAU count
        const { revenue: previousMonthRevenue, mauCount: previousMonthMAU } = lastMonthHistory;

        // Initialize variables for tracking previous month's revenue and MAU till current date
        let previousMonthRevenueTillNow = previousMonthRevenue;
        let previousMAUTillNow = previousMonthMAU;

        // Get the number of days in last month
        const numberOfDaysInLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();

        // If the current day is within last month, calculate revenue and MAU till now
        if (currentDay <= numberOfDaysInLastMonth) {
            // Get stats for current day and previous day of last month
            const currentDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDay);

            const previousDayOfLastMonth = new Date(currentDayOfLastMonth); // Create a copy of the current day of last month
            previousDayOfLastMonth.setDate(previousDayOfLastMonth.getDate() - 1); // Set it to the previous day

            const [currentDayOfLastMonthStats, previousDayOfLastMonthStats] = await Promise.all([
                Services.getAppDailyCounts(currentDayOfLastMonth, abortSignal),
                currentDayOfLastMonth.getDate() === 1 ? null : Services.getAppDailyCounts(previousDayOfLastMonth, abortSignal),
            ]);

            // Calculate MAU delta between current and previous day of last month
            const lastMonthCurrentDayMau = currentDayOfLastMonthStats.mauCount;
            const lastMonthPreviousDayMau = previousDayOfLastMonthStats?.mauCount || 0;
            const currentDayDelta = lastMonthCurrentDayMau - lastMonthPreviousDayMau;

            // Update MAU till now based on current hour
            previousMAUTillNow = lastMonthPreviousDayMau + (currentDayDelta * currentHour) / 24;

            // Calculate revenue till yesterday of last month
            let totalRevenueLastMonthUntilYesterday = 0;
            let promises = [];
            for (let day = 1; day < currentDay; day++) {
                promises.push(Services.getAppDailyCounts(lastMonthDate, abortSignal));
                lastMonthDate.setDate(lastMonthDate.getDate() + 1);
            }

            const dailyStats = await Promise.allSettled(promises);

            for (const dailyStat of dailyStats) {
                if (dailyStat.status === "fulfilled") {
                    totalRevenueLastMonthUntilYesterday += dailyStat.value.revenue.count;
                }
            }

            // Get revenue and users stats for the current day of last month
            const dailyStatsCurrentDayLastMonth = await Services.getAppDailyCounts(lastMonthDate, abortSignal);
            const revenueHourlyTillCurrentHour = dailyStatsCurrentDayLastMonth.revenue.hourly?.slice(0, currentHour) || [];
            let totalRevenueLastMonthCurrentDay = revenueHourlyTillCurrentHour.reduce((acc, val) => acc + val, 0);

            // Add half of the current hour's revenue
            totalRevenueLastMonthCurrentDay += Number((dailyStatsCurrentDayLastMonth.revenue.hourly?.[currentHour] / 2).toFixed(2)) || 0;

            // Calculate total revenue till now for last month
            previousMonthRevenueTillNow = totalRevenueLastMonthUntilYesterday + totalRevenueLastMonthCurrentDay;
        }

        return {
            revenue: {
                currentMonth: currentMonthRevenue,
                lastMonthTillNow: previousMonthRevenueTillNow,
                lastMonth: previousMonthRevenue,
                percentageDiff: ((currentMonthRevenue - previousMonthRevenueTillNow) / previousMonthRevenueTillNow) * 100 || 0,
            },
            mau: {
                currentMonth: currentMonthMAU,
                lastMonthTillNow: previousMAUTillNow,
                lastMonth: previousMonthMAU,
                percentageDiff: ((currentMonthMAU - previousMAUTillNow) / previousMAUTillNow) * 100 || 0,
            },
        };
    };

    getDailyData = async (abortSignal?: AbortSignal) => {
        const currentDate = new Date();
        const currentHour = currentDate.getUTCHours();

        // Get today's daily stats
        const currentDayDailyStats = await Services.getAppDailyCounts(currentDate, abortSignal);
        const currentDayRevenue = currentDayDailyStats.revenue.count;
        const currentDayUsers = currentDayDailyStats.users.count;

        // Calculate yesterday's date
        const yesterdayDate = new Date(currentDate);
        yesterdayDate.setDate(currentDate.getDate() - 1);

        // Get yesterday's daily stats
        const yesterdayDailyStats = await Services.getAppDailyCounts(yesterdayDate, abortSignal);

        const yesterdayRevenue = yesterdayDailyStats.revenue.count;
        const yesterdayUsers = yesterdayDailyStats.users.count;

        const yesterdayRevenueHourly = yesterdayDailyStats.revenue.hourly;
        const yesterdayUsersHourly = yesterdayDailyStats.users.hourly;

        // If there's no data available for yesterday, return default values
        if (!yesterdayRevenueHourly || !yesterdayUsersHourly) {
            console.log("yesterday data not available");

            return {
                revenue: { today: currentDayRevenue, yesterdayTillNow: 0, yesterday: yesterdayRevenue, percentageDiff: 0 },
                dau: { today: currentDayUsers, yesterdayTillNow: 0, yesterday: yesterdayUsers, percentageDiff: 0 },
            };
        }

        // Slice the revenue and users arrays to include hours until the current hour
        const yesterdayRevenueTillCurrentHour = yesterdayRevenueHourly.slice(0, currentHour);
        const yesterdayUsersTillCurrentHour = yesterdayUsersHourly.slice(0, currentHour);

        // Calculate the total revenue and users until the current hour
        let totalRevenueYesterdayTillCurrentHour = yesterdayRevenueTillCurrentHour.reduce((acc, val) => acc + val, 0);
        let totalUsersYesterdayTillCurrentHour = yesterdayUsersTillCurrentHour.reduce((acc, val) => acc + val, 0);

        // Add half of the revenue and users for the current hour
        const halfCurrentHourRevenue = yesterdayRevenueHourly[currentHour] / 2;
        const halfCurrentHourUsers = yesterdayUsersHourly[currentHour] / 2;

        totalRevenueYesterdayTillCurrentHour += halfCurrentHourRevenue;
        totalUsersYesterdayTillCurrentHour += halfCurrentHourUsers;

        return {
            revenue: {
                today: currentDayRevenue,
                yesterdayTillNow: totalRevenueYesterdayTillCurrentHour,
                yesterday: yesterdayRevenue,
                percentageDiff: ((currentDayRevenue - totalRevenueYesterdayTillCurrentHour) / totalRevenueYesterdayTillCurrentHour) * 100 || 0,
            },
            dau: {
                today: currentDayUsers,
                yesterdayTillNow: totalUsersYesterdayTillCurrentHour,
                yesterday: yesterdayUsers,
                percentageDiff: ((currentDayUsers - totalUsersYesterdayTillCurrentHour) / totalUsersYesterdayTillCurrentHour) * 100 || 0,
            },
        };
    };

    renderTooltipTitle = (type: "month" | "day", total: React.ReactNode, thisTime: React.ReactNode) => {
        const { classes } = this.props;

        const currentDate = new Date();

        // Setting the date for the last month
        const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

        // Adjusting the last month's date if the current day exceeds the last day of the last month
        const lastDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        lastMonth.setDate(Math.min(currentDate.getDate(), lastDayOfLastMonth.getDate()));

        // Getting the date for the last month
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        return (
            <div>
                <Typography>{type === "month" ? <Trans>Last Month</Trans> : <Trans>Yesterday</Trans>}</Typography>
                <span className={classes.rowBlock}>
                    <Typography>
                        <Trans>By</Trans>
                        {` ${type === "month" ? DateTimeFormatter.formatDateTimeCustom(lastMonth, "MMMM D") : DateTimeFormatter.formatDateTimeCustom(yesterday, "h:mm A")}`}
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>{thisTime}</Typography>
                </span>
                <span className={classes.rowBlock}>
                    <Typography>
                        <Trans>All</Trans>
                        {` ${type === "month" ? DateTimeFormatter.formatDateTimeCustom(lastMonth, "MMMM") : DateTimeFormatter.formatDateTimeCustom(yesterday, "MMMM D")}`}
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>{total}</Typography>
                </span>
            </div>
        );
    };

    render() {
        const { classes, session, bcHasReadAccess, teamPlanSwapEnabled, teamInfo, appInfo } = this.props;
        const { sendIntercomMessageDialogMode, chooseBillingPlanDialogMode, dailyData, monthlyData, totalRevenue, isBusy } = this.state;

        const isSuper = session.isSuper === true;
        const isTeamAdmin = session.isTeamAdmin === true;
        const isTeamBillable = teamInfo?.isBillable === true;

        const isDeleted = appInfo?.isDeleted === true;
        const isSysDisabled = appInfo?.isEnabled !== true;
        const isUserDisabled = appInfo?.isUserEnabled !== true;

        const hidden = isDeleted || (isSysDisabled && !isSuper) || (isUserDisabled && !isSuper) || !teamPlanSwapEnabled || !isTeamBillable || (isSuper && !isTeamAdmin) || (!isSuper && !isTeamAdmin);
        const appStatus = teamInfo && teamInfo.accountStatus === "PoorStanding" ? (teamInfo?.serviceStatus !== "Suspended" ? AppStatus.APPROACHING_SUSPENSION : AppStatus.SUSPENDED) : appInfo?.app_status;

        return (
            <div className={classes.root}>
                <div className={classes.card} style={{ flexDirection: "row", justifyContent: "flex-start", borderRadius: "0 .5em .5em 0", borderLeft: 0 }}>
                    <ImageWrapper
                        style={{ width: "4.5em", height: "4.5em", marginRight: "0.5em" }}
                        src={!StringUtils.isNullOrEmpty(appInfo?.imageUrl) ? "files/portal/g/" + appInfo?.appId + appInfo?.imageUrl : null}
                        fallback={<NoAppImage />}
                        disableCache={appInfo?.updatedAt || null}
                    />

                    <div style={{ overflow: "hidden", flex: 1 }}>
                        <EllipsisInCenter id={"appName"} labelClassName={classes.value} value={appInfo ? appInfo.appName : "---"} showCopyIcon customValueToCopy={appInfo ? `${appInfo?.appName} (${appInfo?.appId})` : ""} />

                        <LabelWithCopy id={"appId"} copyOnHover={true} labelStyle={{ color: "var(--primary-color, inherit)", fontSize: "0.85em", fontWeight: "bold" }} value={appInfo ? appInfo.appId : null}>
                            {appInfo ? appInfo.appId : "---"}
                        </LabelWithCopy>

                        <span className={classes.nameValuePair} style={{ justifyContent: "space-between" }}>
                            <span>
                                <Chip
                                    style={{ height: "1.5em", width: "fit-content", fontWeight: "bold", color: formatAppStatusChipColor(appStatus), backgroundColor: formatAppStatusChipBackgroundColor(appStatus) }}
                                    label={formatAppStatusLabel(appStatus)}
                                />
                                {(appStatus === AppStatus.APPROACHING_SUSPENSION || appStatus === AppStatus.SUSPENDED) && teamInfo && <FixBillingLink teamInfo={teamInfo} className={classes.value} />}
                            </span>

                            {isSuper && appInfo && (
                                <Tooltip alwaysShow arrow title={<Trans>Send Message</Trans>}>
                                    <IconButton id={"send-message"} onClick={() => this.setState({ sendIntercomMessageDialogMode: DialogMode.CREATE })}>
                                        <EmailIcon style={{ color: "var(--button-primary-background-color, inherit)" }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </span>
                    </div>
                </div>

                <div className={classes.card}>
                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Publishing</Trans>}</Typography>

                        <Chip
                            style={{ height: "1.5em", backgroundColor: formatPublishStateChipBackgroundColor(appInfo?.publishState), color: formatPublishStateChipColor(appInfo?.publishState) }}
                            className={classes.value}
                            label={formatPublishStateLabel(appInfo?.publishState)}
                        />
                    </span>

                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Plan</Trans>}</Typography>

                        <span style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
                            <Typography noWrap className={classes.value}>
                                {appInfo ? appInfo.billing_plan_name : "---"}
                            </Typography>

                            {!hidden && (
                                <Link data-id={"change-plan"} className={classes.value} onClick={this.openChooseBillingPlanDialog}>
                                    <Trans>Change</Trans>
                                </Link>
                            )}
                        </span>
                    </span>

                    <span className={classes.nameValuePair}>
                        <Typography noWrap>{<Trans>Run State</Trans>}</Typography>

                        <Typography className={classes.value} style={{ color: formatRunStateColor(appInfo?.run_state) }} noWrap>
                            {formatRunState(appInfo?.run_state as RunState)}
                        </Typography>
                    </span>
                </div>

                <div className={classes.card}>
                    <Link data-id={`link-to-app-analytics`} style={{ fontWeight: "bold", fontSize: "0.85em" }} onClick={() => this.props.history.push("/app/reports/analytics/core")}>
                        <Trans>Active Users</Trans>
                    </Link>

                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <Tooltip alwaysShow arrow title={this.renderTooltipTitle("day", NumberFormatter.formatInteger(dailyData?.dau?.yesterday), NumberFormatter.formatInteger(dailyData?.dau?.yesterdayTillNow))}>
                            <div>
                                {isBusy ? (
                                    <LoadingProgress type={"linear"} hideLabel />
                                ) : (
                                    <Typography noWrap className={classes.value}>
                                        {NumberFormatter.formatInteger(dailyData?.dau?.today)} DAU
                                    </Typography>
                                )}

                                <span className={classes.content}>
                                    <Typography noWrap style={{ fontSize: "0.85em" }}>
                                        <Trans>Today</Trans>
                                    </Typography>

                                    {!isBusy && <TrendArrow key={`dau-percentageDiff`} percentage={dailyData?.dau?.percentageDiff} />}
                                </span>
                            </div>
                        </Tooltip>

                        <Divider orientation="vertical" style={{ color: "var(--secondary-border-color, inherit)" }} />

                        <Tooltip alwaysShow arrow title={this.renderTooltipTitle("month", NumberFormatter.formatInteger(monthlyData?.mau?.lastMonth), NumberFormatter.formatInteger(monthlyData?.mau?.lastMonthTillNow))}>
                            <div>
                                {isBusy ? (
                                    <LoadingProgress type={"linear"} hideLabel />
                                ) : (
                                    <Typography noWrap className={classes.value}>
                                        {NumberFormatter.formatInteger(monthlyData?.mau?.currentMonth)} MAU
                                    </Typography>
                                )}

                                <span className={classes.content}>
                                    <Typography noWrap style={{ fontSize: "0.85em" }}>
                                        <Trans>Monthly</Trans>
                                    </Typography>

                                    {!isBusy && <TrendArrow key={`mau-percentageDiff`} percentage={monthlyData?.mau?.percentageDiff} />}
                                </span>
                            </div>
                        </Tooltip>
                    </div>
                </div>

                {bcHasReadAccess && totalRevenue > 0 && (
                    <div className={classes.card}>
                        <Link data-id={`link-to-product-analytics`} style={{ fontWeight: "bold", fontSize: "0.85em" }} onClick={() => this.props.history.push("/app/reports/analytics/product")}>
                            <Trans>Revenue</Trans>
                        </Link>

                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <Tooltip alwaysShow arrow title={this.renderTooltipTitle("day", NumberFormatter.formatCurrency(dailyData?.revenue?.yesterday / 100), NumberFormatter.formatCurrency(dailyData?.revenue?.yesterdayTillNow / 100))}>
                                <div>
                                    {isBusy ? (
                                        <LoadingProgress type={"linear"} hideLabel />
                                    ) : (
                                        <Typography noWrap className={classes.value}>
                                            {NumberFormatter.formatCurrency((dailyData?.revenue?.today || 0) / 100)}
                                        </Typography>
                                    )}

                                    <span className={classes.content}>
                                        <Typography noWrap style={{ fontSize: "0.85em" }}>
                                            <Trans>Today</Trans>
                                        </Typography>

                                        {!isBusy && <TrendArrow key={`daily-revenue-percentageDiff`} percentage={dailyData?.revenue?.percentageDiff} />}
                                    </span>
                                </div>
                            </Tooltip>

                            <Divider orientation="vertical" style={{ color: "var(--secondary-border-color, inherit)" }} />

                            <Tooltip alwaysShow arrow title={this.renderTooltipTitle("month", NumberFormatter.formatCurrency(monthlyData?.revenue?.lastMonth / 100), NumberFormatter.formatCurrency(monthlyData?.revenue?.lastMonthTillNow / 100))}>
                                <div>
                                    {isBusy ? (
                                        <LoadingProgress type={"linear"} hideLabel />
                                    ) : (
                                        <Typography noWrap className={classes.value}>
                                            {NumberFormatter.formatCurrency((monthlyData?.revenue?.currentMonth || 0) / 100)}
                                        </Typography>
                                    )}

                                    <span className={classes.content}>
                                        <Typography noWrap style={{ fontSize: "0.85em" }}>
                                            <Trans>Monthly</Trans>
                                        </Typography>

                                        {!isBusy && <TrendArrow key={`monthly-revenue-percentageDiff`} percentage={monthlyData?.revenue?.percentageDiff} />}
                                    </span>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                )}

                {chooseBillingPlanDialogMode === DialogMode.UPDATE && appInfo && <ChooseBillingPlanDialog context={isSuper ? "super" : "team"} appInfo={appInfo} onClose={this.closeChooseBillingPlanDialog} />}
                {sendIntercomMessageDialogMode === DialogMode.CREATE && appInfo && <SendIntercomMessageDialog appInfo={appInfo} onClose={() => this.setState({ sendIntercomMessageDialogMode: null })} />}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "stretch",
            gap: "0.5em",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            maxHeight: "5.6em",
            overflowX: "auto",
            overflowY: "hidden",

            scrollbarWidth: "none",
            "-ms-overflow-style": "none",
            "&::-webkit-scrollbar": {
                display: "none",
            },
            "@media(min-width: 450px) and (max-width: 1420px)": {
                "& > *:nth-child(4)": {
                    display: "none",
                },
            },
            "@media(min-width: 450px) and (max-width: 1160px)": {
                "& > *:nth-child(3)": {
                    display: "none",
                },
            },
            "@media(min-width: 450px) and (max-width: 900px)": {
                "& > *:nth-child(2)": {
                    display: "none",
                },
            },
        },
        card: {
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            backgroundColor: "var(--secondary-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            border: "1px solid var(--secondary-border-color, inherit)",

            padding: "0.5em",
            borderRadius: "0.5em",
            minWidth: "16em",
        },
        content: {
            display: "flex",
            alignItems: "center",
            "& > *:first-child": {
                fontSize: "0.85em",
            },
            "& > *:last-child": {
                display: "flex",
                alignItems: "center",
                fontSize: "0.85em",
            },
        },
        nameValuePair: {
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
            "& > *:first-child": {
                minWidth: "8em",
                fontSize: "0.85em",
            },
        },
        numberValue: {
            display: "flex",
            flex: "0 0 auto",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "0.5em",
            "& > *:first-child": {
                fontSize: "0.85em",
            },
        },
        value: {
            fontSize: "0.85em",
            fontWeight: "bold",
        },
        rowBlock: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "1em",
            marginLeft: "1em",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withPermission("REPORT_DASH_ANALYTICS")(withRouter(withI18n()(withStyles(styles)(AppBanner)))));
